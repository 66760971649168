import React, {useRef, useLayoutEffect} from "react"
import Logo from "../images/letters2.png"
import Layout from "../components/layout"
import Pattern from "../images/pattern.jpg"
import p1 from "../images/p1.jpg"
import p2 from "../images/p2.jpg"
import p3 from "../images/p3.jpg"
import p4 from "../images/p4.jpg"
import p5 from "../images/p5.jpg"
import p6 from "../images/p6.jpg"
import p7 from "../images/p7.jpg"
import p8 from "../images/p8.jpg"
import p9 from "../images/p9.jpg"
import p10 from "../images/p10.jpg"
import p11 from "../images/p11.jpg"
import p12 from "../images/p12.jpg"
import p13 from "../images/p13.jpg"
import p14 from "../images/p14.jpg"
import p15 from "../images/p15.jpg"
import p16 from "../images/p16.jpg"
import p17 from "../images/p17.jpg"
import p18 from "../images/p18.jpg"
import p19 from "../images/p19.jpg"
import p20 from "../images/p20.jpg"
import p21 from "../images/p21.jpg"
import p22 from "../images/p22.jpg"
import p23 from "../images/p23.jpg"
import p24 from "../images/p24.jpg"

const pics = [p1,p2,p3,p4,p5,p6,p7,p8,p9,p10,p11,p12,p13,p14,p15,p16,p17,p18,p19,p20,p21,p22,p23,p24]

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}


const Index = props => {

  let picEls = []

  const slide = {
    width: `300px`,
    padding: `20px`
  }

  const arr = shuffle(pics)

  arr.map(el=>{

    picEls.push(<img style={slide} alt={el} src={el} />)

  })

  const carouselD = useRef("carousel")

  useLayoutEffect(()=>{

    const carouselRect = carouselD.current

    const scroller = () => {

      carouselRect.scrollBy({
        top: 0,
        left: 1,
        behaviour: 'smooth'
     })

    }

    window.setInterval(scroller, 10)

  }, [])

  const picStyle = {
    width: `300px`
  }

  const patternStyle = {
    width: `100%`,
    objectFit: `cover`,
    height: `150px`,
  }

  const carousel = {
    scrollSnapType: `x mandatory`,
    overflow: `auto`,
    whiteSpace: `nowrap`
  }

  

  return [

    <Layout>
      <img style={picStyle} src={Logo} alt={"olimani logo"} />
      <h3>Olimani (<i>brillante en sánscrito</i>) es una marca mexicana de ropa y accesorios para mujeres.</h3>
      <p>Nuestros diseños se basan principalmente en varios elementos de la cultura India. Algunas piezas contienen las características principales de la arquitectura de mezquitas y templos. Otras piezas incorporan mensajes culturales, como la flor loto y los estampados "paisley."</p>
      <p>Actualmente, vendemos ropa para mujeres en Sears y Multibrand. Nuestra tienda en línea de accesorios se está rediseñando, pero nos pueden seguir en <a href="https://www.facebook.com/olimanimx/" target="_blank" rel="noopener noreferrer">Facebook</a> e <a href="https://www.instagram.com/olimanimx/" target="_blank" rel="noopener noreferrer">Instagram</a> para todo tipo de actualizaciones sobre la marca.</p>
      <p>Para informes sobre venta de mayoreo de nuestros productos o dudas y comentarios en general, escríbenos a <a href="mailto:contacto@olimani.mx">contacto@olimani.mx</a></p>
      <br />

      <div style={carousel} ref={carouselD}>
        {picEls}
      </div>

      <img src={Pattern} alt={" olimani paisley pattern"} style={patternStyle} />
    </Layout>

  ]

}

export default Index
