import React from "react"
import SEO from "../components/seo"

const Layout = ( { children} ) => {

    const mainStyle = {

        textAlign: `center`
    }

    return [

        <div style={mainStyle}>
            <div>{children}</div>
        </div>,
        // <SEO />

    ]

}

export default Layout